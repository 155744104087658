<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <h3>Total Contact : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="2" xl="2" class="mb-1">
          <!-- nama_lengkap -->
          <b-form-group label="Email" label-for="contact_email">
            <validation-provider #default="{ errors }" name="Email">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="contact_email"
                  placeholder="Search Email"
                  v-model="filter.contact_email"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="2" xl="2" class="mb-1">
          <!-- nama_lengkap -->
          <b-form-group label="Subject" label-for="contact_subject">
            <validation-provider #default="{ errors }" name="Subject">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="contact_subject"
                  placeholder="Search Subject"
                  v-model="filter.contact_subject"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="2" md="3" class="mb-25">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button-toolbar
        class="d-flex justify-content-between flex-wrap pt-0"
        aria-label="Toolbar with button groups and dropdown menu"
      >
        <!-- group -->
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="refreshPage()"
          >
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-card-body>

    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(document)="data">
        <a :href="data.item.document" v-if="data.item.document" target="_blank"
          >Open</a
        >
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="showDetailContact(data.item)">
            <feather-icon icon="SettingsIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showBalasContact(data.item)">
            <feather-icon icon="SendIcon" size="16" />
            <span class="align-middle ml-50">Balas Contact</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
    <b-modal id="modal-export" hide-footer>
      <template #modal-title> Input Email Tujuan </template>
      <div class="d-block text-center">
        <p>
          Data yang akan diexport lebih dari <b>500</b> baris dan akan dikirim
          via email, Silahkan masukkan email anda!
        </p>
      </div>
      <b-form-group>
        <label for="email">Email:</label>
        <b-form-input
          id="email"
          type="email"
          placeholder="Email Address"
          v-model="email"
        />
      </b-form-group>
      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        @click="exportData()"
      >
        <feather-icon icon="DownloadCloudIcon" /> Export
      </b-button>
    </b-modal>

    <!-- balas contact -->
    <b-modal
      id="modal-send-email"
      size="xl"
      title="Balas Contact"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <validation-observer ref="validateBeforeReplyEmail">
        <b-form @submit.prevent>
          <b-form-group
            v-if="sendemail.orang_id != null"
            label="User"
            label-for="User"
          >
            <validation-provider #default="{ errors }" name="User">
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="user"
                  v-model="sendemail.orang_id"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukan Id User"
                  readonly
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Email" label-for="Email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="email"
                  v-model="sendemail.contact_email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Masukan Email"
                  readonly
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Subject" label-for="Subject">
            <validation-provider
              #default="{ errors }"
              name="Subject"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="subject"
                  v-model="sendemail.contact_subject"
                  placeholder="Masukan Subject Email"
                  class="form-control-merge"
                  :state="errors.length > 0 ? false : null"
                  name="login-password"
                  autocomplete="on"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Message" label-for="Message">
            <validation-provider
              #default="{ errors }"
              name="Message"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <quill-editor
                  v-model="sendemail.contact_message"
                  :options="snowOptions"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <br />
          <b-button
            variant="primary"
            type="submit"
            block
            :disabled="invalid"
            @click="validateBeforeReplyEmail"
          >
            Balas Pesan
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

import { required, email } from "@validations";
import { quillEditor } from "vue-quill-editor";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    quillEditor,
    flatPickr,
    ToastificationContentVue,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 1,
      rows: 0,
      perPage: 25,
      modes: ["multi", "single", "range"],
      fields: [
        { key: "id", label: "id" },
        "contact_email",
        "contact_subject",
        "action",
      ],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      selectedJurusans: [],
      selectedFakultas: [],
      fakultass: [],
      jurusans: [],
      search: null,
      angkatan: null,
      email: null,
      descUnverify: null,
      detailPendidikan: {},
      detailContact: {},
      typeList: [
        { id: 2, name: "Can't find" },
        { id: 3, name: "Input wrong data" },
      ],
      selectedType: { id: 2, name: "Can't find" },
      statusList: [
        { id: 0, name: "Register" },
        { id: 1, name: "Verify" },
        { id: 2, name: "Can't find" },
        { id: 3, name: "Input wrong data" },
      ],

      selectedStatus: [{ id: 0, name: "Register" }],
      filter: {
        contact_email: null,
        contact_subject: null,
      },
      snowOptions: {
        theme: "snow",
      },
      sendemail: {
        orang_id: "",
        contact_email: "",
        contact_subject: "",
        contact_message: "",
      },
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    refreshPage() {
      if (this.currentPage == 1) {
        // this.getPendidikan()
        this.getContact();
      } else {
        this.currentPage = 1;
      }
    },

    validateBeforeReplyEmail() {
      this.$refs.validateBeforeReplyEmail.validate().then((success) => {
        if (success) {
          this.replyMessage();
        }
      });
    },

    showBalasContact(item) {
      this.$bvModal.show("modal-send-email");
      this.sendemail = item;
    },

    async replyMessage() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data message ini akan terkirim ke email users, pastikan email,subject dan message yang di isi sudah benar",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Kirim!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.contact.replyMessage({
                contact_email: this.sendemail.contact_email,
                contact_subject: this.sendemail.contact_subject,
                contact_message: this.sendemail.contact_message,
                orang_id: this.sendemail.orang_id,
              });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Balas Pesan",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async getContact() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
          ...this.filter,
        };
        const { data } = await API.contact.list(form);
        this.data_table = data;
        this.items = data.data;
        this.rows = data.total;

        this.handleMsgSuccess("Berhasil tampilkan data kontak");
      } catch (error) {
        console.log(error);
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    showDetailContact(item) {
      this.$router.push(`/contact/${item.id}`);
    },
  },
  mounted() {
    this.getContact();
  },
  watch: {
    currentPage() {
      this.getContact();
    },
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
